export const enum ModifierLineType {
    Component,
    Ingredient,
    Modifier,
    TextModifier
}

export const enum ModifierLineAction {
    None,
    Substitute,
    Remove,
    Increase,
    Decrease
}

export const enum IngredientEditability {
    DecreaseOnly,
    IncreaseOnly,
    IncreaseDecrease,
    Substitute
}

export const enum ModifierGroupType {
    Products,
    Text,
    Substitutions
}

export const enum LeftRightSetting {
    Whole,
    Left,
    Right,
    Both
}
export interface ICartlineModifierLine {
    Type: ModifierLineType;
    Action: ModifierLineAction;
    SlotId?: number; // Used as the key for component substitutions
    ProductId?: number; // ProductId of substitute component or of recipe product or component for ingredients and modifiers
    ItemId?: string; // Used as a key for modifiers, and the selected value for ingredient substitutes
    LineNum?: number; // Used as a key for ingredients, and the selected value for text modifiers
    ModGroupId?: number; // Modifier group ID; used as a key for modifiers and text modifiers
    ModGroupType?: ModifierGroupType; // Only set for modifiers and text modifiers
    LeftRight: LeftRightSetting;
    Qty: number; // Total quantity
    Name: string;
    UOM: string; // Unit of measure
    Price: number;
}
