/**
 * Helper class for Constants
 */
export namespace Constants {
    export const DEFAULT_SEAT = '1';
    // attributes
    export const ATTRIBUTE_TEXT_VALUE_LENGTH = 1900;
    export const CHECKGROUP_ATTRIBUTE = 'CheckGroup';
    export const CHECKNUMBER_ATTRIBUTE = 'CheckNumber';
    export const TABLENAME_ATTRIBUTE = 'TableName';
    export const NUMBEROFSEATS_ATTRIBUTE = 'Seats';
    export const SEATNUMBER_ATTRIBUTE = 'SeatNumber';
    export const MODIFIERS_ATTRIBUTE = 'Modifiers';
    // extension properties
    export const SELECTEDSEAT_EXT = 'SelectedSeat';
    export const PAIDCART_EXT = 'PaidCart';
    export const RECIPEDEFAULTS_EXT = 'AS_RecipeDefaults';
    export const MODIFIERGROUPS_EXT = 'AS_ModifierGroups';
    export const CURRENTMODIFIERS_EXT = 'AS_CurrentModifiers';
    export const CURRENTMODIFIERS_DELIMITER_EXT = 'AS_CurrentModifiers||';
    export const INGREDIENTSUBGROUPS_EXT = 'AS_IngredientSubGroups';
    export const MODIFIERLINES_EXT = 'AS_ModifierLines';
    export const MODIFIERLINEREQUEST_EXT = 'AS_ModifierLineRequest';
    export const PRICEADJUSTMENT_EXT = 'AS_PriceAdjustment';
    export const PARENTCARTLINEID_EXT = 'AS_ParentCartLineId';
    export const SUBSTITUTIONS_EXT = 'AS_Substitutions';
    export const MANAGEDINVENTORYCOUNT_EXT = 'ManagedInventoryCount';
    export const STARTINGMENUCATEGORY_EXT = 'StartingMenuCategory';
    export const CATEGORYMENUHOURS_EXT = 'AmicisMenuCategoryHours';
    export const HIDEADDTOCARTBUTTON_EXT = 'AmicisHideAddToCartButton';
    export const HIDEUPDATECARTBUTTON_EXT = 'AmicisHideUpdateCartButton';
    export const PRODUCTCATEGORYID_EXT = 'AS_ProductCategoryId';
    export const PRODUCTCONFIGURATIONMODEL_EXT = 'AS_ProductConfigurationModel';
    export const PRODUCTCONFIGURATIONRESPONSEDATA_EXT = 'AS_ProductConfigurationResponseData';
    export const POSDISPLAYNUMOFROWS_EXT = 'AmicisPosDisplayNumberOfRows';
    export const LINENUMBER_EXT = 'AS_LineNumber';
    export const PRODUCTCONFIGURATORMODEL_EXT = 'AS_PCModelXml';
    export const SOLVERSTRATEGY_EXT = 'AS_PCSolverStrategy';
    export const PCCURRENTVALUES_EXT = 'AS_PCCurrentValues';
    export const DEFAULTVARIANTID_EXT = 'AS_DefaultVariantId';
    export const DISCOUNTOFFERID_EXT = 'AS_DiscountOfferId';
    export const PCINITIALMODEL_EXT = 'AS_PCInitialModel';
    export const PCCONFIGURATIONSUMMARY_EXT = 'AS_PCConfigurationSummary';
    export const PCMODELPRICE_EXT = 'AS_PCModelPrice';
    export const PCEXTENSIONPROPERTIES_EXT = [SOLVERSTRATEGY_EXT, PCCURRENTVALUES_EXT];

    // product information
    export const CONTAINSALLERGEN_ATTRIBUTE = 'ContainsAllergen';
    export const CALCIUM_ATTRIBUTE = 'Calcium';
    export const FAT_ATTRIBUTE = 'Fat%';
    export const FIBER_ATTRIBUTE = 'Fiber%';

    export const PREFIXID_CARTLINE = 'kioskCheckoutCartline-';
    export const PREFIXID_DESCRIPTION = 'KioskCheckoutView_Description-';

    // QSR Constants
    export const QSR_CONNECTSMART_ENABLED = 'QSR_CONNECTSMART_ENABLED';
    export const QSR_CONNECTSMART_CONFIG = 'QSR_CONNECTSMART_CONFIG';
}
