import { AttributeValueBase, CartLine, CommerceProperty } from '@msdyn365-commerce/retail-proxy';
import { Component } from 'react';
import { IModel } from '../buybox-ext/actions/PCEntities';
import { IsConfigurationComplete } from '../buybox-ext/components/interfaces';
import { Constants } from './constant';
import { ICartlineModifierLine, LeftRightSetting, ModifierLineAction, ModifierLineType } from './extension-types';

/**
 *
 * LifestyleHelperClasses component
 * @extends {React.Component}
 */

export default class ExtensionPropReaderHelper extends Component {
    public static getCartlineModifierLinesText(cartline: CartLine): string {
        if (cartline.ExtensionProperties) {
            const match = cartline.ExtensionProperties.filter(p => p.Key === 'AS_ModifierLines');
            if (match.length === 1 && match[0].Value?.StringValue) {
                const modLines: ICartlineModifierLine[] = JSON.parse(match[0].Value.StringValue);
                if (Array.isArray(modLines)) {
                    const lineStrings = modLines.map<string>(line => this.getModifierLineText(line));
                    lineStrings.sort();
                    return lineStrings.join('</br>');
                }
            }
        }
        return '';
    }

    public static getModifierLabel = (cartline: CartLine): string => {
        if (cartline.ExtensionProperties) {
            const match = cartline.ExtensionProperties.filter(
                p => p.Key === Constants.MODIFIERLINES_EXT || p.Key === Constants.PCCONFIGURATIONSUMMARY_EXT
            );
            if (match && match.length > 0) {
                return ExtensionPropReaderHelper.getSelectionText(match);
            }
            return '';
        }
        return '';
    };

    public static getSelectionText(props: CommerceProperty[]): string {
        if (props.length > 0 && props[0].Value?.StringValue && props[0].Value?.StringValue !== '[]') {
            return '<h5 className="modification_heading">Your selection:</h5>';
        }
        return '';
    }

    public static getModifierLineText(line: ICartlineModifierLine): string {
        if (line.Type !== ModifierLineType.TextModifier) {
            let prefix = '';
            switch (line.Action) {
                case ModifierLineAction.Substitute:
                    prefix = 'SUB';
                    break;
                case ModifierLineAction.Remove:
                    prefix = 'HOLD';
                    break;
                case ModifierLineAction.Increase:
                    // ingredients get "EXTRA", modifiers get "ADD"
                    prefix = line.Type === ModifierLineType.Ingredient ? 'EXTRA' : 'ADD';
                    break;
                case ModifierLineAction.Decrease:
                    prefix = 'LIGHT';
                    break;
                default:
            }
            if (line.LeftRight === LeftRightSetting.Left) {
                prefix = `LEFT ${prefix}`;
            } else if (line.LeftRight === LeftRightSetting.Right) {
                prefix = `RIGHT ${prefix}`;
            }
            // if (line.Action !== ModifierLineAction.Remove) {
            //   // FORMAT: Prefix Quantity UOM x Name
            //   const qty = Math.round(line.Qty * 100) / 100;
            //   return `${prefix} ${qty} ${line.UOM} × ${line.Name}`;
            // } else {
            //   // FORMAT: Prefix Name
            //   return `${prefix} ${line.Name}`;
            // }
            if (line.Type === ModifierLineType.Modifier) {
                const qty = Math.round(line.Qty * 100) / 100; // round to 2 decimal places
                return `${prefix} ${line.Name} × ${qty}`;
            } else {
                return `${prefix} ${line.Name}`;
            }
        } else {
            return `${line.Name}`;
        }
    }

    public static isPCComplete(props: CommerceProperty[] | undefined): boolean {
        if (props && props?.some(p => p.Key === Constants.SOLVERSTRATEGY_EXT)) {
            const modelString: string | undefined = props.filter(p => p.Key === Constants.PCINITIALMODEL_EXT)[0].Value?.StringValue;
            if (modelString) {
                const model: IModel = JSON.parse(modelString);

                if (model.IsConfigurationComplete !== undefined) {
                    return (
                        IsConfigurationComplete[IsConfigurationComplete[Number(model.IsConfigurationComplete)]] ===
                        IsConfigurationComplete.Complete
                    );
                }
            }
        }
        return true;
    }

    public static isProductConfigurator(props: CommerceProperty[] | undefined): boolean {
        if (props && props?.some(p => p.Key === Constants.SOLVERSTRATEGY_EXT)) {
            return true;
        }
        return false;
    }

    public static copyPCExtensionPropertiesToAttributeValues(extensionProperties: CommerceProperty[]) {
        // create attribute value for product config extension property
        // eslint-disable-next-line
        let attributeValues: AttributeValueBase[] = [];
        // eslint-disable-next-line
        let productConfiguratorExtensionProperties = extensionProperties.filter(
            (extensionProps: CommerceProperty) =>
                extensionProps.Key && Constants.PCEXTENSIONPROPERTIES_EXT.includes(extensionProps.Key) && extensionProps.Value
        );

        // eslint-disable-next-line
        for (let productConfiguratorExtensionProperty of productConfiguratorExtensionProperties) {
            // check if extension property exceeds text length of 1900
            // eslint-disable-next-line
            var propertyValue: string | undefined = undefined;

            if (productConfiguratorExtensionProperty.Value?.StringValue) {
                propertyValue = productConfiguratorExtensionProperty.Value?.StringValue;
            } else {
                propertyValue = productConfiguratorExtensionProperty.Value?.IntegerValue?.toString();
            }

            // eslint-disable-next-line
            if (propertyValue == undefined) {
                continue;
            }

            // add attribute values in chunks if exceeds limit
            if (propertyValue.length > Constants.ATTRIBUTE_TEXT_VALUE_LENGTH) {
                // eslint-disable-next-line
                let propertyValueJsonArray = propertyValue.match(/.{1,1900}/g);

                // eslint-disable-next-line
                if (propertyValueJsonArray != null) {
                    var currentIndex = 10;

                    // eslint-disable-next-line
                    for (let value of propertyValueJsonArray) {
                        // eslint-disable-next-line
                        let attribute = {
                            // eslint-disable-next-line
                            Name: productConfiguratorExtensionProperty.Key + '_' + currentIndex++,
                            ExtensionProperties: [],
                            TextValue: value,
                            TextValueTranslations: [],
                            '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue'
                        };

                        attributeValues.push(attribute);
                    }
                }
            }
            // add to attribute value normally if it is within limit
            else {
                // eslint-disable-next-line
                let attribute = {
                    Name: productConfiguratorExtensionProperty.Key,
                    ExtensionProperties: [],
                    TextValue: propertyValue,
                    TextValueTranslations: [],
                    '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue'
                };

                attributeValues.push(attribute);
            }
        }

        return attributeValues;
    }

    public static getExtensionPropertyByKey(props: CommerceProperty[] | undefined, key: string): CommerceProperty | undefined {
        if (!props) {
            return undefined;
        }

        return props.find(x => x.Key === key);
    }

    public static getConfigurationSummary = (cartline: CartLine): string => {
        if (cartline && cartline.ExtensionProperties) {
            const match = cartline.ExtensionProperties.filter(p => p.Key === Constants.PCCONFIGURATIONSUMMARY_EXT);
            if (match.length === 1 && match[0].Value?.StringValue && match[0].Value?.StringValue !== '[]') {
                return match[0].Value?.StringValue;
            }
        }
        return '';
    };
}
