import { ProductComponent } from '@msdyn365-commerce/retail-proxy';
import { Action, Dispatch } from 'redux';
import { IAttributeGroup } from '../../actions/PCEntities';
import { IProductConfiguratorCartLineCurrentValue } from '../../lifestyle-modify-product';

export interface IIngredientWithSections extends IIngredientData {
    checked: boolean;
    section: LeftRightWhole;
    editabilityOption: IngredientEditability | EditabilityText;
    substitutionLineNum: number;
    itemLineNum: number;
}

export interface IModifiersWithSections extends IIngredientData {
    checked: boolean;
    section: LeftRightWhole;
    editabilityOption?: IngredientEditability | EditabilityText;
}

export interface ILeftRightWholeModifiersArray {
    leftRightModifiersArray: IIngredientWithSections[];
    wholeModifiersArray: IIngredientWithSections[];
}

export enum ModifierLineType {
    Component,
    Ingredient,
    Modifier,
    TextModifier
}

export enum ModifierLineAction {
    None,
    Substitute,
    Remove,
    Increase,
    Decrease
}

export enum ProductType {
    None = 0,
    KitMaster = 1,
    KitVariant = 2,
    Master = 3,
    Standalone = 4,
    Variant = 5
}

export interface IRecipes {
    recipes: IRecipeWithData[];
}

export interface IProductComponents {
    components: IProductComponentWithData[];
}

export interface IRecipeWithData extends IRecipeData {
    checked: boolean;
    name: string;
    defaultId: number;
    extraChargeStr: Number;
    imageURI?: string;
    slots?: IProductComponentWithData[];
}

export interface IComponentWithData {
    Name?: string;
    DisplayName?: string;
    ComponentId: string;
    InstanceId?: string;
    Attributes?: IAttributeWithData[];
    AttributeGroups?: IAttributeGroup[];
    ImageUrl?: string;
    Components?: IComponentWithData[];
    ConfigurationSummary?: string;
    ProductConfiguratorCurrentValues?: IProductConfiguratorCartLineCurrentValue[] | undefined;
    ModelXml?: string;
    SolverStrategy?: number;
}

export interface IAttributeWithData {
    checked: boolean;
    UniqueId?: string;
    InstanceId?: number;
    Name?: string;
    DisplayName?: string;
    Type?: string;
    Domain?: IDomainWithData;
    Default?: string;
    IsUnconditionalMandatory?: boolean;
    IsUnconditionalHidden?: boolean;
    IsUnconditionalReadOnly?: boolean;
    IsConditionalMandatory?: boolean;
    IsConditionalHidden?: boolean;
    IsConditionalReadOnly?: boolean;
    Value?: string;
    DisplayValue?: string;
    ImageUrl?: string;
}

export interface IDomainWithData {
    Id?: string;
    Values?: IDomainWithValue[];
    Type?: string;
}

export interface IDomainWithValue {
    DisplayValue?: string;
    Value?: string;
    ImageUrl?: string;
    checked: boolean;
}

export enum IsConfigurationComplete {
    Complete = 0,
    Contradiction = 1,
    InComplete = 2,
    InvalidCalculationExpression = 3,
    Timeout = 4
}

export interface IProductComponentWithData {
    checked: boolean;
    imageURI: string;
    defaultProductId: number;
    slot: ProductComponent;
}

export interface IRecipeData {
    isRecipe: boolean;
    ingredientsAllowLeftRight: boolean;
    hideIngredients: boolean;
    skipIngredients: boolean;
    modifyOnEntry: boolean;
    defaultIngredients?: IIngredientData[];
    ingredients?: IIngredientData[];
    modifierGroups?: IModifierGroup[];
    ingredientSubGroups?: IIngredientSubstitute[][];
}

// todo need to change this as well to have action and whatever the resonse is required
export interface IModifierLineRequest {
    Type?: ModifierLineType;
    Action?: ModifierLineAction;
    SlotId?: number;
    ProductId?: number;
    ParentProductId?: number;
    ItemId?: string;
    LineNum?: number;
    ModGroupId?: number;
    LeftRight: LeftRightSetting;
    Units: number;
    Name: string;
    UoMSymbol?: string;
    ProductRecId?: number;
    Price?: number;
}

export enum EditabilityText {
    Normal = 'Normal',
    Light = 'Light',
    Extra = 'Extra'
}

export const enum IngredientEditability {
    DecreaseOnly,
    IncreaseOnly,
    IncreaseDecrease,
    Substitute
}

export interface IModifierGroup {
    Type: ModifierGroupType;
    GroupId: number;
    Name: string;
    Description: string;
    MinQuantity: number;
    MaxQuantity: number;
    FreeQuantity: number;
    AllowLeftRight: boolean;
    Quantity: number;
    List: IModifier[];
}

export interface IModifier extends IModifierBase {
    GroupRef: IModifierGroup;
}

export const enum ModifierGroupType {
    Product,
    Text,
    Substitutions,
    Recipe
}

export interface ICurrentModifiers {
    Ingredients: IIngredientData[];
    ModifierGroups: IModifierGroup[];
}

export interface IRecipeDefaults {
    Ingredients: IIngredientData[];
    AllowLeftRight: boolean;
    SkipIngredients: boolean;
    ModifyOnEntry: boolean;
}

export interface IIngredientData extends IModifierBase {
    IncreasePrice: number;
    DecreasePrice: number;
    IncreaseUnits: number;
    DecreaseUnits: number;
    Edit: IngredientEditability;
    SubGroupId: number;
    DoNotShow: boolean;
}

export interface IModifierData extends IModifierBase {
    GroupRef?: IModifierGroup;
}

export interface IModifierBase {
    ProductId: number;
    LineNum: number;
    ItemId: string;
    Name: string;
    Units: number;
    Units2?: number;
    LeftRight?: LeftRightSetting;
    OrigUnits: number;
    MinQuantity?: number;
    MaxQuantity?: number;
    UOM: string;
    Price: number;
    PriceUnit: number;
    QuantityUnit: number;
    Image?: string;
    ProductRecId: number;
}

export interface IIngredientSubstitute {
    ProductRecId: number;
    LineNum: number;
    ItemId: string;
    Name: string;
    QuantityUnit: number;
    UOM: string;
    Price: number;
    PriceUnit: number;
    Image: string;
}

export const enum LeftRightSetting {
    Whole,
    Left,
    Right,
    Both
}

export enum LeftRightWhole {
    left = 'Left',
    right = 'Right',
    whole = 'Whole'
}

export interface IIngredientSubGroups {
    [subGroupId: number]: IIngredientSubstitute[];
}

export interface ICustomizeIngredients {
    allowLeftRight?: boolean;
    defaultIngredients: IIngredientData[];
    leftRightModifiersArray: IIngredientWithSections[];
    wholeModifiersArray: IIngredientWithSections[];
    ingredientsForDifferentSections: IIngredientWithSections[];
    leftRightWhole: LeftRightWhole;
    ingredientSubGroups?: IIngredientSubGroups;
    substitutes: IIngredientSubstitute[];
    substitutionIngredient?: IIngredientData;
    currentSubGroupId?: number;
    modifierLine?: IModifierLine[];
}
export interface IModifierLine {
    Type: ModifierLineType;
    Action: ModifierLineAction;
    ParentProductId?: number;
    ProductId?: number;
    SlotId?: number;
    ItemId: string;
    LineNum?: number;
    ModGroupId?: number;
    ModGroupType?: ModifierGroupType;
    LeftRight: LeftRightSetting;
    Qty: number;
    Name: string;
    UOM: string;
    Price: number;
}

export interface IState {
    customizeIngredients: ICustomizeIngredients;
    customizeModifiers: ICustomizeModifiers;
    customizeRecipes: IRecipes;
    customizeComponents: IProductComponents;
    customizeConfigurator: IComponentWithData;
}

export interface IModifierLocal extends IModifierData {
    section: LeftRightWhole;
    quantity: number;
    modifierGroupId: number;
    checked: boolean;
    modifierLineType?: ModifierLineType;
}

export interface IModifierGroupLocal extends IModifierGroup {
    currentModifiers: IModifierLocal[];
    leftRightWhole: LeftRightWhole;
    currentQuantity: number;
}

export interface ICustomizeModifiers {
    modifierGroups: IModifierGroupLocal[];
    currentGroupId: number;
    modifierLine?: IModifierLine[];
}

export interface IStore {
    dispatch: Dispatch<Action>;
    getState(): () => IState;
}
